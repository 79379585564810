import axios from 'axios'
import { navigate } from 'gatsby'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import { ReactSVG } from 'react-svg'
import { strapi, getBaseUrlM } from 'utils/functions'
var hasher = require('wordpress-hash-node')

// TODO: login returns to previous page

class EditAccountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postedEmail: null,
      error: null,
      loading: false,
    }
  }

  render() {
    const tr = this.props.tr

    const loginUser = JSON.parse(sessionStorage.getItem('loginUser'))
    const onSubmit = (values) => {
      this.setState({ error: null, loading: true })

      const passwordMatch = hasher.CheckPassword(
        values.passwordCurrent,
        loginUser.password2,
      )

      if (
        values.passwordCurrent ||
        values.passwordNew ||
        values.passwordNewConfirm
      ) {
        if (
          !values.passwordCurrent ||
          !values.passwordNew ||
          !values.passwordNewConfirm
        ) {
          setTimeout(
            () => this.setState({ error: 'passwordsMissing', loading: false }),
            500,
          )
          return
        } else if (!passwordMatch) {
          setTimeout(
            () =>
              this.setState({ error: 'passwordCurrentWrong', loading: false }),
            500,
          )
          return
        } else if (values.passwordNew !== values.passwordNewConfirm) {
          setTimeout(
            () =>
              this.setState({ error: 'passwordsNewNotMatch', loading: false }),
            500,
          )
          return
        }
      }

      const updateUser = {
        nameFirst: values.nameFirst,
        nameLast: values.nameLast,
        email: loginUser.email,
        password2: values.passwordNew
          ? hasher.HashPassword(values.passwordNew)
          : loginUser.password,
      }

      let urlPutUser = strapi.url
      urlPutUser += `/users/${loginUser.id}`

      axios({
        method: 'put',
        url: urlPutUser,
        headers: {
          Authorization: `Bearer ${strapi.token}`,
        },
        data: updateUser,
      }).then((response) => {
        const responseUser = response.data
        setTimeout(
          () => this.setState({ error: 'success', loading: false }),
          500,
        )
        sessionStorage.setItem('loginUser', JSON.stringify(responseUser))
        navigate('/my-account/edit-account')
      })
    }

    const loadingClass = this.state.loading ? 'loading' : ''
    return (
      <div className="woocommerce">
        <div className="woocommerce-MyAccount-content">
          <Form
            onSubmit={onSubmit}
            initialValues={{
              nameFirst: loginUser.nameFirst,
              nameLast: loginUser.nameLast,
              email: loginUser.email,
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form
                className="woocommerce-EditAccountForm edit-account"
                onSubmit={handleSubmit}
              >
                {/* <? echo (isset($error) ? $error : ""); ?> */}

                <p>
                  <label>
                    {tr('FORM_ITEM_FIRST_NAME')}*
                    <Field
                      name="nameFirst"
                      component="input"
                      type="text"
                      placeholder={tr('FORM_ITEM_FIRST_NAME')}
                      required
                    />
                  </label>
                </p>
                <p>
                  <label>
                    {tr('FORM_ITEM_LAST_NAME')}*
                    <Field
                      name="nameLast"
                      component="input"
                      type="text"
                      placeholder={tr('FORM_ITEM_LAST_NAME')}
                      required
                    />
                  </label>
                </p>
                <div className="clear" />
                <p>
                  <label>
                    {tr('FORM_ITEM_EMAIL')}*
                    <Field
                      name="email"
                      component="input"
                      type="email"
                      placeholder={tr('FORM_ITEM_EMAIL')}
                      disabled
                      required
                    />
                  </label>
                </p>

                <fieldset>
                  <legend>{tr('FORM_ITEM_PASSWORD_CHANGE')}</legend>
                  <p>
                    <label>
                      {tr('FORM_ITEM_CURRENT_PASSWORD')}
                      {tr('FORM_ITEM_LEAVE_BLANK_UNCHANGED')}
                      <Field
                        name="passwordCurrent"
                        component="input"
                        type="password"
                      />
                    </label>
                  </p>
                  <p>
                    <label>
                      {tr('FORM_ITEM_NEW_PASSWORD')}
                      {tr('FORM_ITEM_LEAVE_BLANK_UNCHANGED')}
                      <Field
                        name="passwordNew"
                        component="input"
                        type="password"
                      />
                    </label>
                  </p>
                  <p>
                    <label>
                      {tr('FORM_ITEM_CONFIRM_NEW_PASSWORD')}
                      <Field
                        name="passwordNewConfirm"
                        component="input"
                        type="password"
                      />
                    </label>
                  </p>
                </fieldset>

                <div className={`submit-wrap full ${loadingClass}`}>
                  <span className="loader">
                    <ReactSVG src="/images/loader.svg" />
                  </span>
                  <input
                    type="submit"
                    className="woocommerce-Button button"
                    name="update"
                    value={tr('FORM_ITEM_SAVE_CHANGES')}
                    disabled={this.state.loading}
                  />
                </div>
              </form>
            )}
          />

          <ul className="error-holder">
            <li
              className={this.state.error === 'passwordsMissing' ? 'show' : ''}
            >
              <strong>{tr('FORM_ITEM_ERROR')}</strong>
              {tr('FORM_UPDATE_ACCOUNT_ERROR_FILL_OUT_ALL_PASSWORDS')}
            </li>
            <li
              className={
                this.state.error === 'passwordCurrentWrong' ? 'show' : ''
              }
            >
              <strong>{tr('FORM_ITEM_ERROR')}</strong>
              {tr('FORM_UPDATE_ACCOUNT_ERROR_CURRENT_PASSWORD_INCORRECT')}
            </li>
            <li
              className={
                this.state.error === 'passwordsNewNotMatch' ? 'show' : ''
              }
            >
              <strong>{tr('FORM_ITEM_ERROR')}</strong>
              {tr('FORM_UPDATE_ACCOUNT_ERROR_PASSWORDS_NOT_MATCH')}
            </li>
            <li className={this.state.error === 'success' ? 'show' : ''}>
              {tr('FORM_UPDATE_ACCOUNT_SUCCESS')}
            </li>
          </ul>
        </div>
      </div>
    )
  }
}
export default EditAccountForm
