import React, { Component } from 'react'
import EditAccountForm from 'components/forms/editAccount'
import Layout from 'components/layout'
import Title from 'components/title'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby'

class EditAccount extends Component {
  UNSAFE_componentWillMount() {
    if (typeof sessionStorage !== 'undefined') {
      if (!sessionStorage.getItem('loginUser')) {
        navigate('/my-account')
      }
    }
  }

  render() {
    const data = this.props.data
    const locale = data.site.siteMetadata.locale
    const translations = data.translations
    const tr = (code) => trFunction(code, translations, locale)
    const page = data.thisPage

    return (
      <Layout>
        {typeof sessionStorage !== 'undefined' &&
          sessionStorage.getItem('loginUser') && (
            <div id="content" className="page-template-page-account">
              <Title title={page.title} />
              <section className="page-content wrap">
                <EditAccountForm tr={tr} data={data} />
              </section>
            </div>
          )}
      </Layout>
    )
  }
}

export default EditAccount

export const editAccountQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "edit-account" }) {
      title
      slug
    }
  }
`
